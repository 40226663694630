import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/imodium-behandelen-diarree/imodium-duo/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/medicaments-imodium/imodium-duo/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="f46faa2a314751ffc395565dc4979d59078b3d2d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/medicaments-imodium/"> Médicaments IMODIUM® </a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li className="active">
                <a href="/fr/medicaments-imodium/imodium-duo/">IMODIUM® Duo</a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
            {/*<div className="widget">
              <div className="inner">
				<a href="/fr/medicaments-imodium/">
                <h4>Quel IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Quel IMODIUM®?"
                  />
                </div>
                <p>
                  Cherchez quel <strong>IMODIUM®</strong> est le plus adapté
                  pour vous.&nbsp;
                </p>
                </a>
              </div>
            </div>*/}
          </div>
          <div className="page nine columns page-43">
            <h1>IMODIUM® Duo comprimés</h1>
            <div className="row">
              <div className="six columns">
                <img
                  alt
                  src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_FR.PNG"
                  style={{
                    width: "322px",
                    height: "247px"
                  }}
                />
              </div>
              <div className="six columns">
                <h4>
                  IMODIUM® Duo en cas de diarrhée accompagnée de discomforts comme des flatulences
                </h4>
                <ul>
                  <li>
                  Soulage les diarrhées accompagnées de flatulences, qui peuvent être à l'origine de crampes ou de ballonnements.
                  </li>
                  <li>Association de lopéramide et simeticone</li>
                  {/*<li>Agit rapidement&nbsp; et efficacement</li>*/}
                  {/*<li>Agit rapidement&nbsp; et efficacement</li>*/}
                </ul>
              </div>
            </div>
            <p>
              <a href="http://bijsluiters.fagg-afmps.be/?localeValue=fr">
                <img
                  alt
                  src="/assets/files/pages/bijlsuiter.jpeg"
                  style={{
                    width: "48px",
                    height: "47px"
                  }}
                />
                Télécharger&nbsp;
              </a>
              <a href="http://bijsluiters.fagg-afmps.be/?localeValue=fr">
                la notice
              </a>
              &nbsp;IMODIUM<sup>®</sup> Duo
            </p>
            <h4>
              Utilisation de IMODIUM<sup>®</sup> Duo
            </h4>
            <p>
              Les comprimés d’IMODIUM<sup>®</sup> Duo sont utilisés pour le
              traitement symptomatique de la diarrhée aiguë accompagnée de
              ballonnement, crampes et flatulence. Ils contiennent du lopéramide
              (2 mg) et du siméticone (125 mg)&nbsp;en tant que substance active
              complémentaire, afin de soulager les plaintes liées à la présence
              de gaz dans l’abdomen. Ils apportent ainsi un double soulagement
              en cas de diarrhée accompagnées de crampes et ballonnements.
            </p>
            <p>
              IMODIUM<sup>®</sup> Duo existe en conditionnement de 18
              comprimés.&nbsp;Le médicament est disponible en pharmacie sans
              prescription.
            </p>
            <h4>
              IMODIUM<sup>®</sup> Duo, comprimés&nbsp;2 mg / 125 mg
            </h4>
            <p>
              IMODIUM<sup>®</sup> Duo convient aux adultes et aux enfants à
              partir de 12 ans. Contient du{" "}
              <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                lopéramide
              </a>{" "}
              et du siméticone. Toujours lire la notice du produit.
            </p>
            <h3>
              <img
                alt
                src="/assets/files/pages/werkzame-stof.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Substance active IMODIUM<sup>®</sup> Duo:
            </h3>
            <p>
              Les substances actives de IMODIUM® Duo sont le chlorhydrate de
              lopéramide et siméticone
            </p>
            <p>&nbsp;</p>
            <h3>
              <img
                alt
                src="/assets/files/pages/toepassingsgebied.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Domaine d'utilisation IMODIUM<sup>®</sup> Duo:
            </h3>
            <p>
              Traitement des symptômes de la diarrhée aiguë, accompagnée de
              crampes abdominales, ballonnements et distension abdominale. Le
              chlorhydrate de lopéramide diminue la diarrhée en ralentissant
              l’activité intestinale accrue. Il renforce en outre l’absorption
              de liquides et de sels de l’intestin. Le siméticone permet la
              dissolution dans l’intestin des gaz responsables des crampes et de
              la sensation de ballonnement.
            </p>
            <p>&nbsp;</p>
            <h4>
              Posologie&nbsp;IMODIUM<sup>®</sup> Duo
            </h4>
            <ul>
              <li>
                <strong>Posologie pour adultes :&nbsp;</strong>La dose initiale
                est de 2 comprimés, suivie, si besoin est, d’1 comprimé après
                chaque selle non moulée. Ne jamais dépasser 4 comprimés dans une
                période de 24 heures.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>
                  <strong>Adolescents de 12 à 18 ans:</strong>
                </strong>
                &nbsp;Croquer d'abord 1 comprimé, puis 1 comprimé après chaque
                selle non moulée. Ne pas dépasser 4 comprimés par jour et 2
                jours de traitement.&nbsp;
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Durée du traitement :&nbsp;</strong>Ne pas utiliser le
                médicament plus de 2 jours sans avis médical.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
